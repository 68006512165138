import { userAxios } from '@/services'

const cardStatusList = async (perPage) => {
    try {
        return await userAxios.get(`card_status/index/${perPage}`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const cardStatusCreate = async (data) => {
    try {
        return await userAxios.post('card_status', data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const cardStatusUpdate = async (id, data) => {
    try {
        return await userAxios.put(`card_status/${id}`, data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const cardStatusDelete = async (id) => {
    try {
        return await userAxios.delete(`card_status/${id}`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const cardStatusPagination = async (perPage, page) => {
    try {
        return await userAxios.get(`card_status/index/${perPage}?page=${page}`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const cardStatusFilter = async (perPage, data) => {
    try {
        return await userAxios.post(`card_status/filter/${perPage}`, data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const cardStatusFilterPagination = async (perPage, page, data ) => {
    try {
        return await userAxios.post(`card_status/filter/${perPage}?page=${page}`, data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}
export default {
    cardStatusList,
    cardStatusCreate,
    cardStatusUpdate,
    cardStatusDelete,
    cardStatusPagination,
    cardStatusFilter,
    cardStatusFilterPagination
}